import {Panel} from "primereact/panel";
import FormControl from "../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {customersGetList} from "../../services/CustomerService";
import {Dropdown} from "primereact/dropdown";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {orderCreate} from "../../services/OrderService";
import {useTranslation} from "react-i18next";

export default function OrderCreate(props) {
    const toast = useRef(null);
    const [customers, setCustomers] = useState([]);
    const [description, setDescription] = useState(null);
    const [paid, setPaid] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [price, setPrice] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation();

    const fetchCustomers = async () => {
        try {
            const customersData = await customersGetList();
            customersData.content.map((customer) => {
                customer.template = customerOptionTemplate(customer)
            })
            setCustomers(customersData.content);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατα την φόρτωση των πελατών',
                life: 3000,
                position: "bottom"
            });
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)
        try {
            const response = await orderCreate({
                "idCustomer": selectedCustomer.id,
                "description": description,
                "paid": paid,
                "completed": completed,
                "price": price,
            });
            setLoading(false)
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Something went wrong while creating new order"),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }

        window.location.href = "/order/list"
    }


    const selectedCustomerTemplate = (customer, props) => {
        if (customer) {
            return customerOptionTemplate(customer)
        }

        return <span>{props.placeholder}</span>;
    };

    const customerOptionTemplate = (customer) => {
        return `${customer.firstName} ${customer.lastName} ${customer.companyName ? ` από ${customer.companyName}` : ""}` +
            `${customer.vatNumber ? ` (Α.Φ.Μ. ${customer.vatNumber})` : ""}`
    };

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <Panel header={t("New Order")}>
            <form onSubmit={formSubmit}>
                <FormControl label={t("Customer")} required={true}>
                    <div className="card flex justify-content-center">
                        <Dropdown value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.value)}
                                  options={customers} optionLabel="name" placeholder={t("Chose Customer")}
                                  filter filterBy={"template"} valueTemplate={selectedCustomerTemplate}
                                  itemTemplate={customerOptionTemplate} className="w-full md:w-14rem"/>
                    </div>
                </FormControl>
                <FormControl label={t("Order")} required={true}>
                    <div className="card flex justify-content-center">
                        <InputTextarea value={description} className={"w-full"}
                                       onChange={(e) => setDescription(e.target.value)}
                                       rows={5} cols={30}/>
                    </div>
                </FormControl>
                <FormControl label={t("Order amount")} required={true}>
                    <div className="p-inputgroup flex-1 w-1/5">
                        <InputText placeholder={t("Order amount")}
                                   onChange={(e) =>
                                       setPrice(e.target.value.replace(",", "."))}
                        />
                        <span className="p-inputgroup-addon">€</span>
                    </div>
                </FormControl>
                <div className="flex items-center mb-4 gap-10">
                    <div className="flex items-center gap-2">
                        <Checkbox inputId="completed" onChange={e => setCompleted(e.checked)}
                                  checked={completed}></Checkbox>
                        <label htmlFor="completed"
                               className="cursor-pointer text-base text-gray-500">{t("Completed")}</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <Checkbox inputId="paid" onChange={e => setPaid(e.checked)}
                                  checked={paid}></Checkbox>
                        <label htmlFor="paid" className="cursor-pointer text-base text-gray-500">{t("Paid")}</label>
                    </div>
                </div>
                <div className={"flex justify-end"}>
                    <Button label={t("Create Order")} loading={loading} disabled={loading}/>
                </div>
            </form>
        </Panel>
    </>;
}
