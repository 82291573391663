import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {repairDelete, repairsGetList, repairsSearch} from "../../services/RepairService";
import {Tag} from "primereact/tag";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {InputText} from "primereact/inputtext";
import {Link} from "react-router-dom";
import {GrPrint} from "react-icons/gr";

export default function RepairList(props) {

    const toast = useRef(null);
    const searchQueryField = useRef(null);

    const [repairs, setRepairs] = useState([]);
    const [selectedRepairs, setSelectedRepairs] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("NEW");
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();

    const fetchRepairs = async () => {
        setLoading(true);
        try {
            let repairsResult = [];
            if (searchQuery != null && searchQuery.length > 3) {
                repairsResult = await repairsSearch(searchQuery);
                setRepairs(repairsResult);
                setTotalRecords(repairsResult.length);
            } else {
                repairsResult = await repairsGetList(page, sort);
                setRepairs(repairsResult.serviceItems);
                setTotalRecords(repairsResult.pagesTotalNumber * repairsResult.serviceItems.length);
            }
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while loading repairs'),
                life: 3000,
                position: "bottom"
            });
        } finally {
            setLoading(false);  // Ensure loading is set to false, regardless of success or error
        }
    };


    useEffect(() => {
        fetchRepairs();
    }, [page, sort, size, searchQuery]);


    const deleteCustomer = async (repair) => {
        setLoading(true)
        try {
            await repairDelete(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while deleting repair'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: 'Διαγράφηκε',
            detail: 'Ο πελάτης διαγράφηκε με επιτυχία',
            life: 3000
        });
        fetchRepairs();
    }

    const closeModal = () => {
    }

    const confirmDelete = (customer) => {
        confirmDialog({
            message: 'Έιστε σίγουρος πως θέλετε να διαγράψετε αυτήν την επισκευή;',
            header: 'Διαγραφή επισκευής',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                deleteCustomer(customer)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>{t("Repairs")}</span>
            <div className={"flex gap-4"}>
                <span className="p-input-icon-right">
                    {searchQuery == null ? <i className="pi pi-search"/> :
                        <i className="pi pi-times cursor-pointer" onClick={() => {
                            setSearchQuery(null)
                            searchQueryField.current.value = null;
                        }}/>}
                    <InputText placeholder={t("Search...")} ref={searchQueryField} onChange={(e) => {
                        setSearchQuery(e.target.value)
                    }}/>
                </span>
                <Link to={"/repair/create"}>
                    <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} label={t("New Repair")}/>
                </Link>
            </div>
        </div>
    }


    const getActionsButtons = (repair) => {
        return <>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={t("Edit Repair")}
                    tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"} onClick={() => {
                setLoading(true)
                window.location.href = `/repair/${repair.id}/edit`
            }
            }/>}/>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(repair)
                    }}
                    tooltip={t("Delete Repair")} icon={<TrashIcon color={"grey"}/>}/>

            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    tooltip={t("Reprint Repair Delivery Receipt")} icon={<GrPrint color={"grey"}/>} onClick={() => {
                window.location.href = `/repair/${repair.id}/delivery-receipt`
            }
            }/>
        </>
    }
    const onPage = (e) => {
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const onSort = (e) => {
        setSort(`${e.sortField},${e.sortOrder === 1 ? "desc" : "asc"}`)
    }

    const showBulkOptions = (e) => {
        return <Button
            outlined disabled={selectedRepairs.length === 0} label="Διαγραφή" icon="pi pi-times"
            onClick={() => {
                confirmDialog({
                    message: 'Έιστε σίγουρος πως θέλετε να διαγράψετε τους επιλεγμένες επισκευές;',
                    header: 'Διαγραφή επιλεγμένων επισκευών',
                    icon: 'pi pi-info-circle',
                    defaultFocus: 'reject',
                    acceptClassName: 'p-button-primary',
                    acceptLabel: t("Yes"),
                    rejectLabel: t("No"),
                    accept: () => {
                        selectedRepairs.map((customer) => {
                            deleteCustomer(customer)
                        })
                    },
                    reject: () => {
                        closeModal()
                    }
                });
            }}/>
    }

    const deliveredFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center justify-between">
                <label htmlFor="verified-filter" className="font-bold">
                    {t("Paid")}
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value}
                                  onChange={(e) => options.filterCallback(e.value)}/>
            </div>
        );
    };

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <Panel header={getHeader()}>
            <DataTable
                value={repairs} showGridlines selectionMode={'checkbox'} selection={selectedRepairs}
                onSelectionChange={(e) => setSelectedRepairs(e.value)}
                stripedRows className={"w-full"} size={"small"} paginator
                totalRecords={totalRecords} lazy loading={loading} onSort={onSort}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={t("Total: {totalRecords} Repairs")}
                first={first} onPage={onPage} rows={size} pageCount={5}
                emptyMessage={"Δεν βρέθηκαν επισκευές"}
                dataKey="id" paginatorLeft={showBulkOptions()}
            >
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                <Column field="dateAdd" header={t("Date")} body body={(repair) => {
                    const date = new Date(repair.dateAdd * 1000);
                    const day = String(date.getDate()).padStart(2, '0'); // Get the day, ensure two digits
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed), ensure two digits
                    const year = date.getFullYear(); // Get the full year
                    return `${day}/${month}/${year}`;
                }}/>
                <Column field="code" header={t("Code")} body={(repair) => {
                    return <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={repair.description}
                                   tooltipOptions={{position: 'bottom'}}>
                        <Tag severity="success" value={`#${repair.code}`}> </Tag>
                    </Button>
                }}/>
                <Column field="customerName" header={t("Customer Name")}/>
                <Column field="customerTelephone" header={t("Customer Telephone")}></Column>
                <Column field="serialNumber" header={t("Serial Number")}></Column>
                <Column field="price" header={t("Repair Amount")} body={(repair) => {
                    if (repair.price == null) {
                        return ""
                    }
                    return <b>{repair.price.toFixed(2) + "€"}</b>
                }}></Column>
                <Column field="serviced" header={t("Serviced")} dataType="boolean" bodyClassName="text-center"
                        style={{minWidth: '8rem'}}
                        body={(order) => {
                            return order.serviced ?
                                <Tag className="mr-2" icon="pi pi-check" severity="success" value={t("Serviced")}/> : ""
                        }}
                />
                <Column field="sentSms" header={t("Sent SMS")} dataType="boolean" bodyClassName="text-center"
                        style={{minWidth: '8rem'}}
                        body={(order) => {
                            return order.sentSms ?
                                <Tag className="mr-2" icon="pi pi-check" severity="success" value={t("Sent SMS")}/> : ""
                        }}
                />
                <Column field="delivered" header={t("Delivered")} dataType="boolean" bodyClassName="text-center"
                        style={{minWidth: '8rem'}} body={(order) => {
                    return order.delivered ?
                        <Tag className="mr-2" icon="pi pi-check" severity="success" value={t("Delivered")}/> : ""
                }} />
                <Column field="actions" header={t("Actions")} body={getActionsButtons}/>
            </DataTable>
        </Panel>
    </>;
}
