import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {locale} from "primereact/api";
import {Dropdown} from "primereact/dropdown";

export default function LanguageSelector() {
    const [language, setLanguage] = useState(window.LANGUAGE_OPTIONS[0]);
    const {t, i18n} = useTranslation();

    const setLanguageToLocalStorage = (option) => {
        localStorage.setItem("language", JSON.stringify(option))
        locale(option.code)
        setLanguage(option)
        i18n.changeLanguage(option.code).then(() => {
            window.location.reload()
        });
    }

    useEffect(() => {
        let language = JSON.parse(localStorage.getItem("language"))
        if(language==null){
            language = window.LANGUAGE_OPTIONS[0]
        }
        locale(language.code)
        i18n.changeLanguage(language.code)
        setLanguage(language)
    }, []);

    return <Dropdown value={language} onChange={(e) => setLanguageToLocalStorage(e.value)}
                     itemTemplate={(option) => {
                         return <div className={"flex gap-2 items-center"}>
                             {option.icon}
                             <span>{option.name}</span>
                         </div>
                     }}
                     valueTemplate={(value) => {
                         return language.name
                     }}
                     options={window.LANGUAGE_OPTIONS} optionLabel="name"
                     placeholder="Επιλογή γλώσσας"/>
}
