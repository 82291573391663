import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {Toast} from "primereact/toast";
import LoadingAnimation from "../../components/UI/LoadingAnimation/LoadingAnimation";
import {useTranslation} from "react-i18next";
import {
    repairGetDetails,
    repairMarkAsDelivered,
    repairMarkAsServiced,
    repairSendSMS, repairUpdate
} from "../../services/RepairService";
import {Toolbar} from "primereact/toolbar";
import "./RepairEdit.css"
import * as PropTypes from "prop-types";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import FormControl from "../../components/UI/FormControl";
import {Checkbox} from "primereact/checkbox";
import {InputTextarea} from "primereact/inputtextarea";

function IconField(props) {
    return null;
}

IconField.propTypes = {
    iconPosition: PropTypes.string, children: PropTypes.node
};

function InputIcon(props) {
    return null;
}

InputIcon.propTypes = {className: PropTypes.string};
export default function RepairEdit(props) {

    const {idRepair} = useParams();
    const [repair, setRepair] = useState(null);
    const {t, i18n} = useTranslation();
    const toast = useRef(null);
    const [description, setDescription] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [customerTelephone, setCustomerTelephone] = useState(null);
    const [paid, setPaid] = useState(false);
    const [price, setPrice] = useState(false);
    const [payInAdvance, setPayInAdvance] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPayInAdvance, setShowPayInAdvance] = useState(false);
    const [serialNumber, setSerialNumber] = useState(null);

    const fetchData = async () => {
        try {
            setLoading(true)

            // load order
            const repairData = await repairGetDetails(idRepair);
            setRepair(repairData);
            setCustomerName(repairData.customerName)
            setCustomerTelephone(repairData.customerTelephone)
            setPrice(parseFloat(repairData.price))
            setPayInAdvance(parseFloat(repairData.payInAdvanceAmount))
            setSerialNumber(parseFloat(repairData.serialNumber))
            if (parseFloat(repairData.payInAdvanceAmount) > 0) {
                setShowPayInAdvance(true)
            }
            setDescription(repairData.description)

            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch customer data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()

        try {
            await repairUpdate(repair.id, customerName, customerTelephone, serialNumber, description,
                paid, price, payInAdvance);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατά την δημιουργία του πελάτη',
                life: 3000,
                position: "bottom"
            });
            return
        }

        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });

    }

    const sendSMS = async (repair) => {
        setLoading(true)
        try {
            await repairSendSMS(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while sending SMS'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success', summary: 'Επιτυχία', detail: 'To SMS απεστάλη με επιτυχία', life: 3000
        });
        setLoading(false)
    }

    const markAsDelivered = async (repair) => {
        setLoading(true)
        try {
            await repairMarkAsDelivered(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while marking repair as delivered'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success', summary: 'Επιτυχία', detail: 'Η επισκευή παραδόθηκε', life: 3000
        });
        setLoading(false)
    }
    const markAsServiced = async (repair) => {
        setLoading(true)
        try {
            await repairMarkAsServiced(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while marking repair as serviced'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success', summary: 'Επιτυχία', detail: 'Η επισκευή ενημερώθηκε', life: 3000
        });
        setLoading(false)
    }
    const confirmSMS = () => {
        confirmDialog({
            message: 'Έιστε σίγουρος πως θέλετε να στείλετε SMS στον πελάτη να περάσει να παραλάβει;',
            header: 'Αποστολή SMS',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                sendSMS(repair)
            },
            reject: () => {
            }
        });
    }
    const confirmDelivered = () => {
        confirmDialog({
            message: 'Έιστε σίγουρος πως θέλετε να παραδόσετε την επισκευή;',
            header: 'Παραδόθηκε',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                markAsDelivered(repair)
            },
            reject: () => {
            }
        });
    }
    const confirmServiced = () => {
        confirmDialog({
            message: 'Έιστε σίγουρος πως θέλετε να ορίσετε την επισκευή ως επισκευασμένη;',
            header: 'Επισκευασμένη',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                markAsServiced(repair)
            },
            reject: () => {
            }
        });
    }

    const startContent = (<React.Fragment>
        <Button icon="pi pi-print" className="mr-2" onClick={() => {
            window.location.href = `/repair/${repair.id}/delivery-receipt`
        }} tooltip={t("Reprint Repair Delivery Receipt")} tooltipOptions={{position: 'bottom'}}/>
        <div className={"relative"}>
            {repair && repair.sentSms && <div className={"sentSMS-icon"}></div>}
            <Button icon="pi pi-envelope" className="mr-2 bg-gray-400 border-gray-400" onClick={confirmSMS}
                    tooltipOptions={{position: 'bottom'}} tooltip={t("Send SMS")}/>
        </div>

    </React.Fragment>);


    const endContent = (<div className={"flex gap-2"}>
        <Button label="Επισκευάστηκε" icon="pi pi-wrench" onClick={confirmServiced}
                className={"bg-gray-400 border-gray-400"} tooltipOptions={{position: 'bottom'}}
                tooltip={t("Ορισμός ως επισκευασμένη")}></Button>
        <Button label="Παραδόθηκε" icon="pi pi-check" onClick={confirmDelivered} tooltipOptions={{position: 'bottom'}}
                tooltip={t("Ορισμός ως παραδομένη")}></Button>
    </div>);


    return <>
        <ConfirmDialog/>
        <LoadingAnimation state={loading}/>
        <Toast ref={toast} position="bottom-right"/>

        <Panel header={t("Ενέργειες Επισκευής") + " #" + (repair && repair.code)} className={"relative mb-10"}>
            <Toolbar start={startContent} end={endContent}/>
        </Panel>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-10 mb-10"}>
                <div className={"flex gap-10 flex-col w-full"}>
                    <Panel header={t("Customer")} className={"w-full"}>
                        <FormControl label={t("Customer Name")} required={true}>
                            <div className="card flex justify-content-center">
                                <InputText placeholder={t("Customer Name")} className={"w-full"}
                                           defaultValue={customerName}
                                           onChange={(e) =>
                                               setCustomerName(e.target.value.replace(",", "."))}
                                />
                            </div>
                        </FormControl>
                        <FormControl label={t("Customer Telephone")} required={true}>
                            <div className="card flex justify-content-center">
                                <InputText placeholder={t("Customer Telephone")} keyfilter="int" className={"w-full"}
                                           defaultValue={customerTelephone}
                                           onChange={(e) =>
                                               setCustomerTelephone(e.target.value.replace(",", "."))}
                                />
                            </div>
                        </FormControl>
                    </Panel>
                    <Panel header={t("Pricing")} className={"w-full"}>
                        <div className={"flex gap-5"}>
                            <FormControl label={t("Repair amount")}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Repair amount")} value={price}
                                               onChange={(e) =>
                                                   setPrice(e.target.value.replace(",", "."))}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                            <FormControl label={t("Pay In Advance Amount")}
                                         className={!showPayInAdvance && "invisible"}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Pay In Advance Amount")}
                                               onChange={(e) =>
                                                   setPayInAdvance(e.target.value.replace(",", "."))}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                        </div>

                        <div className="flex items-center mb-4 gap-10">
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="payinadvance" onChange={e => setShowPayInAdvance(e.checked)}
                                          checked={showPayInAdvance}></Checkbox>
                                <label htmlFor="payinadvance"
                                       className="cursor-pointer text-base text-gray-500">{t("Pay In Advance")}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="paid" onChange={e => setPaid(e.checked)}
                                          checked={paid}></Checkbox>
                                <label htmlFor="paid"
                                       className="cursor-pointer text-base text-gray-500">{t("Paid")}</label>
                            </div>
                        </div>
                    </Panel>
                </div>

                <Panel header={t("New Repair")} className={"w-full"}>
                    <FormControl label={t("Serial Number")}>
                        <div className="card flex justify-content-center">
                            <InputText placeholder={t("Serial Number")} className={"w-full"} defaultValue={serialNumber}
                                       onChange={(e) =>
                                           setSerialNumber(e.target.value)}
                            />
                        </div>
                    </FormControl>
                    <FormControl label={t("Description")} required={true}>
                        <div className="card flex justify-content-center">
                            <InputTextarea defaultValue={description} className={"w-full"}
                                           placeholder={t("Description")}
                                           onChange={(e) => setDescription(e.target.value)}
                                           rows={12} cols={50}/>
                        </div>
                    </FormControl>
                </Panel>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Update Repair")} loading={loading} disabled={loading}/>
            </div>
        </form>
    </>

}
