import styles from "./MainLayout.module.css"
import {BreadCrumb} from "primereact/breadcrumb";
import {HiHome} from "react-icons/hi";
import Navbar from "../../components/NavBar/Navbar";
import LanguageSelector from "../../components/LanguageSelector";
import React from "react";

export default function MainLayout(props) {
    return (
        <>
            <div className={"w-full flex"}>
                <Navbar language={props.language}  setLanguage={props.setLanguage}/>
                <div className={`bg-zinc-200 h-screen overflow-auto ${styles.mainContainer}`}>
                    <div className={"p-10"}>
                        <div className={"flex items-center w-full justify-between mb-5"}>
                            <BreadCrumb className={" bg-transparent p-0"} model={props.breadcrumb}
                                        home={{icon: <HiHome color={"#7C7C7C"}/>, url: "/"}}/>
                            <LanguageSelector/>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}
