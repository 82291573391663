import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";


export const repairsGetList = async (page = 0, sort = "NEW", serviced = null, delivered = null) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/service-items?page=${page + 1}&sort=${sort}${serviced != null ? `&serviced=${serviced}` : ""}` +
                `${delivered != null ? `&delivered=${delivered}` : ""}`
        });
        return response
    } catch (error) {
        throw error;
    }
};
export const repairGetDetails = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/service-item/${idRepair}`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairsSearch = async (searchQuery) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/service-item/search?searchQuery=${searchQuery}`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairSendSMS = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/service-item/${idRepair}/sms`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairDelete = async (idRepair) => {
    try {
        const response = await callApiWithAuthentication({
            method: "delete",
            url: `${window.API_URL}/service-item/${idRepair}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};
export const repairUpdate = async (
    idRepair,
    customerName,
    customerTelephone,
    serialNumber,
    description,
    paid,
    price,
    payInAdvance,
) => {
    try {
        const formData = new FormData();
        formData.append('customerName', customerName);
        formData.append('customerTelephone', customerTelephone);
        formData.append('serialNumber', serialNumber);
        formData.append('description', description);
        formData.append('paid', paid);
        formData.append('price', price);
        formData.append('payInAdvance', payInAdvance);
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairMarkAsDelivered = async (idRepair) => {
    try {
        const formData = new FormData();
        formData.append('delivered', true);
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const repairMarkAsServiced = async (idRepair) => {
    try {
        const formData = new FormData();
        formData.append('serviced', true);
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/service-item/${idRepair}/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });
        return response
    } catch (error) {
        throw error;
    }
};

export async function repairCreate(requestBody) {
    try {
        // Create a FormData object
        const formData = new FormData();

        // Append text fields to FormData
        formData.append('customerName', requestBody.customerName);
        formData.append('customerTelephone', requestBody.customerTelephone);
        formData.append('description', requestBody.description);
        formData.append('price', requestBody.price);
        formData.append('payInAdvanceAmount', requestBody.payInAdvanceAmount);
        formData.append('serialNumber', requestBody.serialNumber);

        // Append files (images) to FormData, if any
        if (requestBody.images && requestBody.images.length > 0) {
            requestBody.images.forEach((image, index) => {
                formData.append(`images`, image);  // Attach each file
            });
        }

        // Append imageIds to FormData (assuming it's an array)
        if (requestBody.imageIds && requestBody.imageIds.length > 0) {
            requestBody.imageIds.forEach((id, index) => {
                formData.append(`imageIds`, id);  // Attach each imageId
            });
        }

        // Make API call using FormData
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/v2/service-item`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'  // Make sure the content type is multipart/form-data
            }
        });

        return response;

    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
}
