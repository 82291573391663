import {Panel} from "primereact/panel";
import FormControl from "../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {useTranslation} from "react-i18next";
import {repairCreate} from "../../services/RepairService";

export default function RepairCreate(props) {
    const toast = useRef(null);
    const [description, setDescription] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [customerTelephone, setCustomerTelephone] = useState(null);
    const [paid, setPaid] = useState(false);
    const [price, setPrice] = useState(false);
    const [payInAdvance, setPayInAdvance] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPayInAdvance, setShowPayInAdvance] = useState(false);
    const [serialNumber, setSerialNumber] = useState(null);
    const {t, i18n} = useTranslation();

    useEffect(() => {

    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)
        try {
            let payInAdvanceAmount = payInAdvance
            if (!showPayInAdvance) {
                payInAdvanceAmount = 0
            }
            if (paid) {
                payInAdvanceAmount = price
            }

            const response = await repairCreate({
                "customerName": customerName,
                "customerTelephone": customerTelephone,
                "description": description,
                "price": parseFloat(price),
                "payInAdvanceAmount": payInAdvanceAmount,
                "serialNumber": serialNumber,
            });
            setLoading(false)
            window.open(`/repair/${response.id}/delivery-receipt`, '_blank').focus();
            window.location.href = "/repair/list"

        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Something went wrong while creating new repair"),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }

        window.location.href = "/repair/list"
    }


    const customerOptionTemplate = (customer) => {
        return `${customer.firstName} ${customer.lastName} ${customer.companyName ? ` από ${customer.companyName}` : ""}` +
            `${customer.vatNumber ? ` (Α.Φ.Μ. ${customer.vatNumber})` : ""}`
    };

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-10 mb-10"}>
                <div className={"flex gap-10 flex-col w-full"}>
                    <Panel header={t("Customer")} className={"w-full"}>
                        <FormControl label={t("Customer Name")} required={true}>
                            <div className="card flex justify-content-center">
                                <InputText placeholder={t("Customer Name")} className={"w-full"}
                                           onChange={(e) =>
                                               setCustomerName(e.target.value.replace(",", "."))}
                                />
                            </div>
                        </FormControl>
                        <FormControl label={t("Customer Telephone")} required={true}>
                            <div className="card flex justify-content-center">
                                <InputText placeholder={t("Customer Telephone")} keyfilter="int" className={"w-full"}
                                           onChange={(e) =>
                                               setCustomerTelephone(e.target.value.replace(",", "."))}
                                />
                            </div>
                        </FormControl>
                    </Panel>
                    <Panel header={t("Pricing")} className={"w-full"}>
                        <div className={"flex gap-5"}>
                            <FormControl label={t("Repair amount")}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Repair amount")}
                                               onChange={(e) =>
                                                   setPrice(e.target.value.replace(",", "."))}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                            <FormControl label={t("Pay In Advance Amount")}
                                         className={!showPayInAdvance && "invisible"}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Pay In Advance Amount")}
                                               onChange={(e) =>
                                                   setPayInAdvance(e.target.value.replace(",", "."))}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                        </div>

                        <div className="flex items-center mb-4 gap-10">
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="payinadvance" onChange={e => setShowPayInAdvance(e.checked)}
                                          checked={showPayInAdvance}></Checkbox>
                                <label htmlFor="payinadvance"
                                       className="cursor-pointer text-base text-gray-500">{t("Pay In Advance")}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="paid" onChange={e => setPaid(e.checked)}
                                          checked={paid}></Checkbox>
                                <label htmlFor="paid"
                                       className="cursor-pointer text-base text-gray-500">{t("Paid")}</label>
                            </div>
                        </div>
                    </Panel>
                </div>

                <Panel header={t("New Repair")} className={"w-full"}>
                    <FormControl label={t("Serial Number")}>
                        <div className="card flex justify-content-center">
                            <InputText placeholder={t("Serial Number")} className={"w-full"}
                                       onChange={(e) =>
                                           setSerialNumber(e.target.value)}
                            />
                        </div>
                    </FormControl>
                    <FormControl label={t("Description")} required={true}>
                        <div className="card flex justify-content-center">
                            <InputTextarea value={description} className={"w-full"} placeholder={t("Description")}
                                           onChange={(e) => setDescription(e.target.value)}
                                           rows={12} cols={50}/>
                        </div>
                    </FormControl>
                </Panel>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Create Repair")} loading={loading} disabled={loading}/>
            </div>
        </form>
    </>
}
