import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import LoadingAnimation from '../../components/UI/LoadingAnimation/LoadingAnimation';
import {userGetDetails} from '../../services/UserService';
import {loadProtectedImage} from '../../services/ImageService';
import {useTranslation} from "react-i18next";
import {repairGetDetails} from "../../services/RepairService";

const RepairDeliveryReceipt = () => {
    const {idRepair} = useParams();
    const [repair, setRepair] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const componentRef = useRef();
    const {t, i18n} = useTranslation();

    const fetchData = async () => {
        setLoading(true)
        try {
            const [userDetails, repairDetails] = await Promise.all([
                userGetDetails(),
                repairGetDetails(idRepair),
            ]);
            setImageUrl(await loadProtectedImage(userDetails.image.url));
            setRepair(repairDetails);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch customer data:', error);
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (repair) {
            handlePrint();
        }
    }, [repair]);

    const formatDate = (dateAdd) => {
        const date = new Date(dateAdd * 1000);
        const day = String(date.getDate()).padStart(2, '0'); // Get the day, ensure two digits
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed), ensure two digits
        const year = date.getFullYear(); // Get the full year
        return `${day}/${month}/${year}`;
    }

    return (
        <div>
            <div ref={componentRef} style={{
                fontSize: "13px"
            }}>
                <div style={{padding: "20px", fontSize: "20px", minHeight: "960px"}}>
                    <table style={{marginBottom: '15px', width: '100%', fontSize: "16px"}}>
                        <tbody>
                        <tr>
                            <td style={{width: "300px", paddingRight: "10"}}>
                                {imageUrl != null && <img src={imageUrl} alt="User"/>}
                            </td>
                            <td>
                                <b>ΧΑΤΖΗΚΩΝΣΤΑΝΤΙΝΟΥ ΝΙΚΟΛΑΟΣ ΤΟΥ ΝΑΠΟΛΕΩΝ</b>
                                <p>ΚΑΛΛΙΡΟΗΣ 73, ΑΘΗΝΑ, 11745 </p>
                                <p>ΛΙΑΝΙΚΟ ΕΜΠΟΡΙΟ ΗΛΕΚΤΡΟΝΙΚΩΝ ΥΠΟΛΟΓΙΣΤΩΝ ΚΑΙ ΠΕΡΙΦΕΡΕΙΑΚΩΝ </p>
                                <p>ΑΦΜ: 076870384 </p>
                                <p>ΤΗΛΕΦΩΝΟ: 2103380200 </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <br/>
                    <b className={"block text-center"}>ΔΕΛΤΙΟ ΠΟΣΟΤΙΚΗΣ ΠΑΡΑΛΑΒΗΣ ΕΠΙΣΚΕΥΗΣ
                        #{repair && repair.code}</b>
                    <p className={"text-center mb-4"}>{formatDate(repair && repair.dateAdd)}</p>
                    <br/>
                    <div className={"bg-gray-200 p-6 rounded mb-4"}>
                        <b className={"mb-2"}>Στοχεία πελάτη</b>
                        <p>{repair && repair.customerName}</p>
                        <p>{repair && repair.customerTelephone}</p>
                    </div>
                    <div className={"bg-gray-200 p-6 rounded mb-4"}>
                        <b className={"mb-2"}>Κόστος Επισκευής</b>
                        <p>{(repair != null && repair.price != null) ? repair.price.toFixed(2) + "€" : "ΔΕΝ ΟΡΙΣΤΗΚΕ"}</p>
                    </div>
                    <div className={"bg-gray-200 p-6 rounded mb-4"}>
                        <b className={"mb-2"}>Περιγραφή Επισκευής</b>
                        <p>S/N: {repair && repair.serialNumber}</p>
                        <p>{repair && repair.description}</p>
                    </div>
                </div>
                <div style={{padding: "20px"}}>
                    <p>ΟΡΟΙ ΧΡΗΣΗΣ</p>
                    <p style={{fontSize: "7px"}}>ΜΕ ΤΗΝ ΠΑΡΑΛΑΒΗ ΑΥΤΟΥ ΤΟΥ ΑΠΟΚΟΜΜΑΤΟΣ, ΕΞΟΥΣΙΟΔΟΤΩ ΤΗΝ ΕΤΑΙΡΕΙΑ ALL
                        REPAIR
                        ΚΑΙ ΤΟΥΣ ΤΕΧΝΙΚΟΥΣ ΤΗΣ ΝΑ ΠΡΟΧΩΡΗΣΟΥΝ ΣΤΗΝ ΕΠΙΣΚΕΥΗ ΤΩΝ ΠΡΟΪΟΝΤΩΝ ΠΟΥ ΑΝΑΓΡΑΦΟΝΤΑΙ ΠΑΡΑΠΑΝΩ. ΔΕΝ
                        ΕΠΙΡΡΙΠΤΩ ΟΥΔΕΜΙΑ ΕΥΘΥΝΗ ΣΤΗΝ ΕΤΑΙΡΕΙΑ ALL REPAIR ΚΑΙ ΓΙΑ ΟΠΟΙΑΔΗΠΟΤΕ ΑΛΛΗ ΒΛΑΒΗ ΜΠΟΡΕΙ ΝΑ
                        ΔΗΜΙΟΥΡΓΗΘΕΙ ΣΤΟ ΠΡΟΪΟΝ ΜΟΥ ΣΤΟ ΜΕΛΛΟΝ ΕΚΤΟΣ ΕΑΝ ΟΦΕΙΛΕΤΑΙ ΚΑΠΟΙΟ ΑΠΟ ΤΑ ΑΝΤΑΛΛΑΚΤΙΚΑ ΤΑ ΟΠΟΙΑ
                        ΑΝΤΙΚΑΤΑΣΤΑΘΗΚΑΝ ΚΑΤΑ ΤΗ ΔΙΑΡΚΕΙΑ ΤΗΣ ΕΠΙΣΚΕΥΗΣ ΠΟΥ ΕΓΙΝΕ. Ο ΧΡΌΝΟΣ ΕΠΙΣΚΕΥΉΣ ΕΞΑΡΤΆΤΑΙ ΑΠΌ ΤΗΝ
                        ΔΙΑΘΕΣΙΜΌΤΗΤΑ ΤΟΥ ΑΝΤΑΛΛΑΚΤΙΚΟΎ ΤΟΥ ΕΚΆΣΤΟΤΕ ΚΑΤΑΣΚΕΥΑΣΤΉ, ΑΛΛΆ ΚΑΙ ΑΠΡΌΟΠΤΩΝ ΕΠΙΠΛΟΚΏΝ ΚΑΤΆ ΤΗ
                        ΔΙΑΔΙΚΑΣΊΑ ΑΠΟΚΑΤΆΣΤΑΣΗΣ. Η ΕΤΑΙΡΕΊΑ ALL REPAIR ΔΕN ΦΈΡΕΙ ΚΑΜΊΑ ΕΥΘΎΝΗ ΓΙΑ ΟΠΟΙΑΔΉΠΟΤΕ
                        ΚΑΘΥΣΤΈΡΗΣΗ ΚΑΘΏΣ ΚΑΙ ΓΙΑ ΤΙΣ ΣΥΝΈΠΕΙΕΣ ΠΟΥ ΑΥΤΉ ΜΠΟΡΕΊ ΝΑ ΈΧΕΙ ΓΙΑ ΤΟΝ ΠΕΛΆΤΗ. ΟΙ ΣΥΣΚΕΥΈΣ ΜΕΤΆ
                        ΤΗΝ ΟΛΟΚΛΉΡΩΣΗ ΤΗΣ ΕΠΙΣΚΕΥΉΣ ΘΑ ΚΡΑΤΙΟΎΝΤΑΙ ΣΤΟ ΚΑΤΆΣΤΗΜΑ ΓΙΑ 30 ΗΜΈΡΕΣ ΜΕΤΆ ΤΗΝ ΕΙΔΟΠΟΊΗΣΗ ΣΤΟΝ
                        ΠΕΛΆΤΗ ΓΙΑ ΠΑΡΑΛΑΒΉ ΜΕΤΆ ΤΟ ΠΈΡΑΣ ΤΩΝ ΗΜΕΡΏΝ ΤΟ ΚΑΤΆΣΤΗΜΑ ΈΧΕΙ ΚΆΘΕ ΝΌΜΙΜΟ ΔΙΚΑΊΩΜΑ ΝΑ
                        ΚΑΤΑΣΤΡΈΨΕΙ ΚΑΙ ΝΑ ΣΤΕΊΛΕΙ ΚΑΙ ΣΥΣΚΕΥΈΣ ΓΙΑ ΑΝΑΚΎΚΛΩΣΗ</p>
                </div>
            </div>
            <LoadingAnimation state={loading}/>
        </div>
    );
}

export default RepairDeliveryReceipt;
