import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ReactComponent as GreekFlag} from './assets/GreekFlag.svg';
import {ReactComponent as EnglishFlag} from './assets/EnglishFlag.svg';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
// window.API_URL = `http://localhost:80`
window.API_URL = `https://api.serviceme.gr`
window.LANGUAGE_OPTIONS = [
    { name: 'Ελληνικά', code: 'el' , icon: <GreekFlag/>},
    { name: 'English', code: 'en', icon: <EnglishFlag/>},
];
window.ORDER_MANAGEMENT_CUSTOMERS = ["karabelas"];


root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
