import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export async function orderCreate(requestBody) {
    try {
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/order/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}

export const ordersGetList = async (page, size, sort, searchQuery, filters) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/orders/?page=${page}&size=${size}&sort=${sort}&searchQuery=${searchQuery}` +
                `${filters.paid.value != null ? `&paid=${filters.paid.value}` : ""}`
        });
        return response
    } catch (error) {
        throw error;
    }
};


export const orderDelete = async (id) => {
    try {
        const response = await callApiWithAuthentication({
            method: "delete",
            url: `${window.API_URL}/order/${id}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const orderUpdate = async (id, requestBody) => {
    try {
        return await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/order/${id}/`,
            data: requestBody
        })
    } catch (error) {
        throw error;
    }
};

export const orderPrint = async (id) => {
    try {
        return await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/order/${id}/print`,
        })
    } catch (error) {
        throw error;
    }
};


export const orderGetDetails = async (idOrder) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/order/${idOrder}`
        });
        return response
    } catch (error) {
        throw error;
    }
};
